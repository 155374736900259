import React from "react";
import {Link} from "react-router-dom";

export default class PageNotFound extends React.Component {
    render() {
        return (
            <div className={'text-center'}>
                <h1>Page Not Found</h1>
                <p><strong>Please go home by clicking <Link to={'/'}>here</Link>.</strong></p>
            </div>
        );
    }
}