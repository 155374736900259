import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

export default class Home extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <h2>Members</h2>
                </Row>
                <Row>
                    {this.props.scopes.includes('secretariat') &&
                        <>
                            <Col md className={'text-center'}>
                                <Button variant='outline-secondary' as={Link} to={'/members/add'}>New Member</Button>
                            </Col>
                            <Col md className={'text-center'}>
                                <Button variant='outline-secondary' as={Link} to={'/members/edit'}>Edit/Delete Member</Button>
                            </Col>
                            <Col md className={'text-center'}>
                                <Button variant='outline-secondary' as={Link} to={'/applications'}>New Member Forms</Button>
                            </Col>
                            {/*<Col md className={'text-center'}>*/}
                            {/*    <Button variant='outline-secondary' as={Link} to={'/events'}>Events</Button>*/}
                            {/*</Col>*/}
                            {/*<Col md className={'text-center'}>*/}
                            {/*    <Button variant='outline-secondary' as={Link} to={'/members/email'}>View By Email</Button>*/}
                            {/*</Col>*/}
                            <Col md className={'text-center'}>
                                <Button variant='outline-secondary' as={Link} to={'/add-club'}>Add Club</Button>
                            </Col>
                        </>
                    }
                    {/*{(this.props.scopes.includes('secretariat') || this.props.scopes.includes('nationalBoard'))  &&*/}
                    {/*<>*/}
                    {/*    <Col md className={'text-center'}>*/}
                    {/*        <Button variant='outline-secondary' as={Link} to={'/events'}>Events</Button>*/}
                    {/*    </Col>*/}
                    {/*</>*/}
                    {/*}*/}
                    {/*<Col md className={'text-center'}>*/}
                    {/*    <Button variant='outline-secondary' as={Link} to={'/ab-form'}>A & B Forms</Button>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
        );
    }
}