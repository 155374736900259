import React from "react";
import PageNotFound from "./PageNotFound";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

export default class SinglePendingMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            application: {},
            error: false,
            rejected: false,
            accepted: false
        }
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
    }

    componentDidMount() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/applications/pending?user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
        })
            .then(resp => resp.json())
            .then(resp => {
                let filtered = resp.filter(item => stringToHash(item['datetime']) + '' === this.props.hash + '')
                if (filtered.length === 0) {
                    this.setState({
                        ...this.state,
                        error: true
                    })
                } else {
                    this.setState({
                        ...this.state,
                        application: filtered[0],
                        loading: false,
                        error: false
                    })
                }
            })
    }

    accept() {

        let currentState = this.state

        let clubNum = JSON.parse(currentState['application']['data'])['clubNum'];
        if (clubNum === "00" || clubNum === "0" || parseInt(clubNum) === 0) {
            let newData = JSON.parse(this.state['application']['data'])
            newData['clubNum'] = "Amigo";
            currentState['application']['data'] = JSON.stringify(newData);
        }

        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/applications/accept', {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'POST',
            body: JSON.stringify({user: this.props.user, datetime: this.state.application.datetime})
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...currentState,
                    accepted: true
                })
            })
    }

    reject() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/applications/reject', {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'POST',
            body: JSON.stringify({user: this.props.user, datetime: this.state.application.datetime})
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    rejected: true
                })
            })
    }

    render() {
        if (this.state.error) {
            return <PageNotFound />
        }

        if (this.state.loading) {
            return <div>Loading</div>
        }

        let applicationData = JSON.parse(this.state.application.data);
        delete applicationData['validated']
        delete applicationData['file']
        delete applicationData['loading']
        delete applicationData['recaptcha']

        return (
            <Container>
                {this.state.rejected && <Alert variant={'warning'}>Member Rejected</Alert>}
                {this.state.accepted && <Alert variant={'success'}>Member Accepted</Alert>}
                <Row>
                    <Table striped bordered hover>
                        <thead>
                        {/*<tr>*/}
                        {/*    <th>Name</th>*/}
                        {/*    <th>Club</th>*/}
                        {/*    <th>City</th>*/}
                        {/*    <th>Mobile</th>*/}
                        {/*    <th>View More</th>*/}
                        {/*</tr>*/}
                        </thead>
                        <tbody>
                        <tr>
                            <td>Submission Time</td>
                            <td>{this.state.application.datetime}</td>
                        </tr>
                        {Object.keys(applicationData).map(key => {

                            if (key === 'imageUrl') {
                                return (
                                    <tr key={key}>
                                        <td>Image</td>
                                        <td><img src={applicationData[key]} width={'30%'}/></td>
                                    </tr>
                                )
                            }

                            return (
                                <tr key={key}>
                                    <td>{key}</td>
                                    <td>{applicationData[key]}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Row>
                {this.state.rejected && <Alert variant={'warning'}>Member Rejected</Alert>}
                {this.state.accepted && <Alert variant={'success'}>Member Accepted</Alert>}
                <Row>
                    <Col className={'text-center'}>
                        <Button variant={'outline-secondary'} onClick={this.accept} disabled={this.state.rejected || this.state.accepted}>Accept as Member</Button>
                    </Col>
                    <Col className={'text-center'}>
                        <Button variant={'outline-secondary'} onClick={this.reject} disabled={this.state.rejected || this.state.accepted}>Remove Application</Button>
                    </Col>
                </Row>
                {this.state.rejected && <Alert variant={'warning'}>Member Rejected</Alert>}
                {this.state.accepted && <Alert variant={'success'}>Member Accepted</Alert>}
            </Container>
        );
    }
}

function stringToHash(string) {

    let hash = 0;

    if (string.length == 0) return hash;

    for (let i = 0; i < string.length; i++) {
        let char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return hash;
}