import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Loading from "../Components/Loading";
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";

export default class ViewMemberByEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.changeSearch = this.changeSearch.bind(this);
        this.search = this.search.bind(this);
    }

    changeSearch(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    search() {
        this.setState({
            ...this.state,
            loading: true
        })
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/email?user=' + this.props.user + '&email=' + this.state.email, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    response: resp,
                    loading: false
                })
            })

    }

    render() {
        if (this.state.loading) {
            return (
                <Loading />
            )
        }
        return (
            <Container>
                <Row>
                    <Col lg>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email"
                                          onChange={this.changeSearch} value={this.state.email}/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Button variant='outline-secondary' onClick={this.search}>Search</Button>
                </Row>
                <Row style={{marginTop: '3%'}}>
                    {this.state.response &&
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Club</th>
                            <th>City</th>
                            <th>Mobile</th>
                            <th>View More</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{this.state.response.name}</td>
                                <td>{this.state.response.club}</td>
                                <td>{this.state.response.city}</td>
                                <td>{this.state.response.phone}</td>
                                <td><Button variant='outline-secondary' as={Link} to={'/members/edit/' + this.state.response.memberid}>View/Edit Member</Button></td>
                            </tr>
                        </tbody>
                    </Table>
                    }
                </Row>
            </Container>
        );
    }
}