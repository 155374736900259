import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Auth0Provider} from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain="tangentindia.eu.auth0.com"
        clientId="utOFMT2T9TVBVFT0enQ8GhKLeLpiJqDH"
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://tangentindia.eu.auth0.com/api/v2/",
            scope: "create:users read:current_user create:users_app_metadata read:users update:users delete:users read:users_app_metadata update:users_app_metadata delete:users_app_metadata"
        }}
        redirectUri={window.location.origin}
        audience="https://tangentindia.eu.auth0.com/api/v2/"
        scope="create:users read:current_user create:users_app_metadata read:users update:users delete:users read:users_app_metadata update:users_app_metadata delete:users_app_metadata"
    >
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </Auth0Provider>
);
