import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";

export default class ViewABForms extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col md>
                        <h1>View A&B Forms</h1>
                    </Col>
                    <Col md>
                        <Button variant='outline-secondary' as={Link} to={'/ab-form/submit'}>Submit Form</Button>
                    </Col>
                </Row>
                View All Forms
            </Container>
        );
    }
}