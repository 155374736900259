import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import Navigation from "./Components/Navigation";
import Home from "./Page/Home";
import PageNotFound from "./Page/PageNotFound";
import MemberList from "./Page/MemberList";
import AddMember from "./Page/AddMember";
import EditMember from "./Page/EditMember";
import {withAuth0} from "@auth0/auth0-react";
import Loading from "./Components/Loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import NewEvent from "./Page/NewEvent";
import Events from "./Page/Events";
import EditEvent from "./Page/EditEvent";
import ViewABForms from "./Page/ViewABForms";
import ABForm from "./Page/ABForm";
import ViewMemberByEmail from "./Page/ViewMemberByEmail";
import AddClub from "./Page/AddClub";
import PendingMemberList from "./Page/PendingMemberList";
import SinglePendingMember from "./Page/SinglePendingMember";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        let {user, isLoading, isAuthenticated, getAccessTokenSilently} = this.props.auth0;

        if (isLoading) {
            return (
                <div>
                    <Loading/>
                </div>
            )
        }

        if (!isAuthenticated) {
            const {loginWithRedirect} = this.props.auth0;

            return (
                <Container>
                    <Row>
                        <div style={{textAlign: 'center'}}>
                            <img src='https://assets.tangentindia.org/assets/national-logo.jpg'
                                 style={{width: '30%', marginTop: '3%', marginBottom: '3%'}}/>
                        </div>
                    </Row>
                    <Row>
                        <Button variant='outline-secondary' onClick={() => loginWithRedirect()}>Login</Button>
                    </Row>
                </Container>
            )
        }

        if (!this.state.accessToken) {
            getAccessTokenSilently()
                .then(accessToken => {
                    fetch('https://tangentindia.eu.auth0.com/api/v2/users/' + user.sub, {
                        headers: new Headers({
                            'Authorization': 'Bearer ' + accessToken
                        }),
                    })
                        .then(resp => resp.json())
                        .then(resp => {
                            this.setState({
                                ...this.state,
                                accessToken: accessToken,
                                scopes: Object.keys(resp['app_metadata']).filter(key => resp['app_metadata'][key])
                            })
                        })
                    })
        }

        if (!this.state.accessToken) {
            return (
                <Loading/>
            )
        }

        return (
            <Router>
                <Navigation/>
                <Switch>
                    <Route path="/members">
                        {this.state.scopes.includes('secretariat') ? <MembersRouter accessToken={this.state.accessToken} user={user.sub}/> : <PageNotFound />}
                    </Route>
                    <Route path="/events">
                        {this.state.scopes.includes('secretariat') || this.state.scopes.includes('nationalBoard') ? <EventsRouter accessToken={this.state.accessToken} user={user.sub}/> : <PageNotFound />}
                    </Route>
                    {/*<Route path="/ab-form">*/}
                    {/*    <ABFormRouter accessToken={this.state.accessToken} user={user.sub} scopes={this.state.scopes} />*/}
                    {/*</Route>*/}
                    <Route path={`/add-club`}>
                        <AddClub accessToken={this.state.accessToken} user={user.sub}/>
                    </Route>
                    <Route path={`/applications`}>
                        <PendingMemberRouter accessToken={this.state.accessToken} user={user.sub}/>
                    </Route>
                    <Route path="/" exact>
                        <Home scopes={this.state.scopes}/>
                    </Route>
                    <Route path="/">
                        <PageNotFound />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

function PendingMemberRouter(props) {
    let match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${match.path}/:hash`}>
                    <SinglePendingMemberRouter accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={match.path}>
                    <PendingMemberList accessToken={props.accessToken} user={props.user}/>
                </Route>
            </Switch>
        </div>
    )
}

function SinglePendingMemberRouter(props) {
    let {hash} = useParams();
    return (
        <SinglePendingMember hash={hash} accessToken={props.accessToken} user={props.user}/>
    )
}

function MembersRouter(props) {
    let match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${match.path}/add`}>
                    <AddMember accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={`${match.path}/email`}>
                    <ViewMemberByEmail accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={`${match.path}/edit/:memberId`}>
                    <EditMemberRouter accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={`${match.path}/edit`}>
                    <MemberList accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={match.path}>
                    <PageNotFound/>
                </Route>
            </Switch>
        </div>
    );
}

function EditMemberRouter(props) {
    let {memberId} = useParams();
    return (
        <EditMember memberId={memberId} accessToken={props.accessToken} user={props.user}/>
    )
}

function EventsRouter(props) {
    let match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${match.path}/add`}>
                    <NewEvent accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={`${match.path}/:eventId`}>
                    <EditEventRouter accessToken={props.accessToken} user={props.user}/>
                </Route>
                <Route path={match.path}>
                    <Events accessToken={props.accessToken} user={props.user}/>
                </Route>
            </Switch>
        </div>
    );
}

function EditEventRouter(props) {
    let {eventId} = useParams();
    return (
        <EditEvent eventId={eventId} accessToken={props.accessToken} user={props.user}/>
    )
}

function ABFormRouter(props) {
    let match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${match.path}/submit`}>
                    {props.scopes.includes('club') ? <ABForm accessToken={props.accessToken} user={props.user}/> : <PageNotFound />}
                </Route>
                <Route path={`${match.path}/:submissionId`}>
                    {props.scopes.includes('secretariat') || props.scopes.includes('nationalBoard')? <ViewABFormRouter accessToken={props.accessToken} user={props.user}/> : <PageNotFound /> }
                </Route>
                <Route path={match.path}>
                    <ViewABForms accessToken={props.accessToken} user={props.user}/>
                </Route>
            </Switch>
        </div>
    );
}

function ViewABFormRouter(props) {
    let {submissionId} = useParams();
    return (
        <EditEvent submissionId={submissionId} accessToken={props.accessToken} user={props.user}/>
    )
}


export default withAuth0(App);