import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../Components/Loading";
import Alert from "react-bootstrap/Alert";

export default class EditMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            deleteUserPrep: false
        }
        this.changeState = this.changeState.bind(this);
        this.submit = this.submit.bind(this);
        this.changeDeleteState = this.changeDeleteState.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    componentDidMount() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/member?id=' + this.props.memberId + '&user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    loading: false,
                    ...resp,
                    updatedEmail: resp['email'],
                    club: resp['clubNum']
                })
            })
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    submit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            ...this.state,
            loading: true
        })
        fetch(' https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/member', {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'PUT',
            body: JSON.stringify({...this.state, user: this.props.user, id: this.props.memberId})
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    success: true,
                    loading: false
                })
            })
    }

    changeDeleteState(e) {
        this.setState({
            ...this.state,
            deleteUserPrep: !this.state.deleteUserPrep
        })
    }

    deleteUser() {
        this.setState({
            ...this.state,
            loading: true
        })
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/member?id=' + this.props.memberId + '&user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'DELETE',
            body: JSON.stringify({
                user: this.props.user,
                id: this.props.memberId
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    loading: false,
                    deleted: true
                })
            })
    }

    updateEmail() {
        this.setState({
            ...this.state,
            loading: true
        })
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/email?id=' + this.props.memberId + '&user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'PUT',
            body: JSON.stringify({
                user: this.props.user,
                id: this.props.memberId,
                updatedEmail: this.state.updatedEmail
            })
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    loading: false,
                    success: true
                })
            })
    }

    updatePassword() {
        // fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/password?id=' + this.props.memberId + '&user=' + this.props.user, {
        //     headers: new Headers({
        //         'Authorization': 'Bearer ' + this.props.accessToken
        //     }),
        //     method: 'PUT',
        //     body: JSON.stringify({
        //         user: this.props.user,
        //         id: this.props.memberId,
        //         updatedEmail: this.state.updatedEmail
        //     })
        // })
        //     .then(resp => resp.json())
        //     .then(resp => {
        //         console.log(resp);
        //     })
    }

    render() {

        // console.log(this.state)

        return (
            <Container>
                {this.state.loading && <Loading/>}
                {this.state.deleted && <Alert variant='success'>Member Deleted</Alert>}
                {this.state.success && <Alert variant='success'>Member Edited</Alert>}
                <Form onSubmit={this.submit}>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter name" value={this.state.name} name="name"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="club">
                                <Form.Label>Club</Form.Label>
                                <Form.Control type="number" placeholder="Enter club" value={this.state.club} name="club"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="Enter city" value={this.state.city} name="city"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="pan">
                                <Form.Label>PAN</Form.Label>
                                <Form.Control type="text" placeholder="Enter pan" value={this.state.pan} name="pan"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="dob">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control type="date" placeholder="Enter dob" value={this.state.dob} name="dob"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="doa">
                                <Form.Label>Date of Anniversary</Form.Label>
                                <Form.Control type="date" placeholder="Enter doa" value={this.state.doa} name="doa"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="number" placeholder="Enter phone" value={this.state.phone}
                                              name="phone" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={this.state.email}
                                              name="email" disabled/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="enter address"
                                              value={this.state.address} name="address" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="pincode">
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control type="text" placeholder="Enter pincode" value={this.state.pincode}
                                              name="pincode" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="companyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter company" value={this.state.companyName}
                                              name="companyName" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="designation">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control type="text" placeholder="Enter designation" value={this.state.designation}
                                              name="designation" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="profession">
                                <Form.Label>Profession</Form.Label>
                                <Form.Control type="text" placeholder="Enter profession" value={this.state.profession}
                                              name="profession" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="specialization">
                                <Form.Label>Specialization</Form.Label>
                                <Form.Control type="text" placeholder="Enter specialization"
                                              value={this.state.specialization} name="specialization"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="officeAddress">
                                <Form.Label>Office Address</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter office address"
                                              value={this.state.officeAddress} name="officeAddress"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="spouseName">
                                <Form.Label>Spouse Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter spouse Name" value={this.state.spouseName}
                                              name="spouseName" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="spouseMobile">
                                <Form.Label>Spouse Mobile</Form.Label>
                                <Form.Control type="number" placeholder="Enter spouse mobile"
                                              value={this.state.spouseMobile} name="spouseMobile"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="spouseDob">
                                <Form.Label>Spouse Dob</Form.Label>
                                <Form.Control type="date" placeholder="Enter spouse dob" value={this.state.spouseDob}
                                              name="spouseDob" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="post">
                                <Form.Label>Post</Form.Label>
                                <Form.Select aria-label="post" name={'post'} onChange={this.changeState} value={this.state.post}>
                                    <option value='' name='post'></option>
                                    {posts.map(post => {
                                        return (
                                            <option name='post' value={post} key={post}>{post}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.loading && <Loading/>}
                    {this.state.deleted && <Alert variant='success'>Member Deleted</Alert>}
                    {this.state.success && <Alert variant='success'>Member Edited</Alert>}
                    <Row>
                        <Button type='submit' variant='outline-secondary' onClick={this.submit}>Submit</Button>
                    </Row>
                    <Row>
                        <hr style={{width: '100%', marginTop: '2%', marginBottom: '2%'}}/>
                    </Row>
                    <Row>
                        <Row>
                            <h2>Update Email</h2>
                        </Row>
                        <Row>
                            <Col md>
                                <Form.Group className="mb-3" controlId="updatedEmail">
                                    <Form.Label>Update Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter new email"
                                                  value={this.state.updatedEmail} name="updatedEmail"
                                                  onChange={this.changeState}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Button variant='outline-secondary' onClick={this.updateEmail}>Update Email</Button>
                        </Row>
                    </Row>
                    <Row>
                        <hr style={{width: '100%', marginTop: '2%', marginBottom: '2%'}}/>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Button variant='outline-secondary' onClick={this.updatePassword}>Update Password</Button>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                    {/*    <hr style={{width: '100%', marginTop: '2%', marginBottom: '2%'}}/>*/}
                    {/*</Row>*/}
                    <Row>
                        <Row>
                            <h2>Delete User</h2>
                        </Row>
                        <Row>
                            <Col md className='text-center'>
                                <Form.Check
                                    type='checkbox'
                                    label='I want to delete this user'
                                    onChange={this.changeDeleteState}
                                    value={this.state.deleteUserPrep}
                                />
                            </Col>
                            <Col md className='text-center'>
                                <Button variant='outline-secondary' onClick={this.deleteUser}
                                        disabled={!this.state.deleteUserPrep}>Delete User</Button>
                            </Col>
                        </Row>
                    </Row>
                </Form>
            </Container>
        );
    }
}

let posts = [
    'President',
    'Immediate Past President',
    'Vice President',
    'National Secretary',
    'National Treasurer',
    'National Editor',
    'International Relations Officer',
    // 'National Rules Convenor',
    // 'National YAP Convenor',
    // 'National Fund Raising Convenor',
    // '41 BnB Convenor',
    'National Fellowship Convenor',
    // 'National FTDE Convenor',
    'National Extension Convenor',
    'NAGM Convenor',
    // 'Howzzat Convenor',
    // 'SYJ Convenor',
    // 'RTI Coordinator',
    // 'Secretariat Convenor',
    'Supply House Convenor',
    // '41 Insurance Convenor',
    'Project Crimson Convenor',
    'Special Invitee',
    // 'Area Chairman',
    // 'Area Vice Chairman',
    // 'Area Secretary Treasurer',
    'Club Chairperson',
    'Club Secretary',
    // 'Contact 41er',
    // 'Club Convenor',
]