import React from "react";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {Container} from "@mui/material";
import Row from "react-bootstrap/Row";

export default class PendingMemberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            loading: true,
            applications: []
        }
    }

    componentDidMount() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/applications/pending?user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    applications: resp,
                    loading: false
                })
            })
    }

    render() {
        if (this.state.loading) {
            return <div>Loading</div>
        }

        const columns: GridColDef[] = [
            // {field: 'memberid', headerName: 'ID', width: 100, sortable: false},
            // {field: 'clubStr', headerName: 'Club', width: 200},
            {field: 'datetime', headerName: 'Submission Time', width: 300},
            {field: 'name', headerName: 'Name', width: 300},
            {field: 'email', headerName: 'Email', width: 150},
            {field: 'mobile', headerName: 'Phone', width: 150, sortable: false},
            {
                field: "application",
                headerName: "View Application",
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    return <Button variant='outline-secondary' as={Link} to={'/applications/' + stringToHash(params.row.datetime)}>View
                        Application</Button>;
                }
            },
        ];

        return (
            <Container>
                <h1>Pending Applications</h1>
                <Row>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.datetime}
                        rows={this.state.applications}
                        columns={columns}
                        pageSize={this.state.pageSize}
                        rowsPerPageOptions={[25, 50, 100]}
                        onPageSizeChange={(e) => this.setState({...this.state, pageSize: e})}
                        disableSelectionOnClick
                    />
                </Row>
            </Container>
        );
    }
}

function stringToHash(string) {

    let hash = 0;

    if (string.length == 0) return hash;

    for (let i = 0; i < string.length; i++) {
        let char = string.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return hash;
}