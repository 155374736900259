import React from "react";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Container} from "@mui/material";
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Loading from "../Components/Loading";
import Row from "react-bootstrap/Row";

export default class MemberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            loading: true
        }
        this.downloadMemberData = this.downloadMemberData.bind(this);
    }

    componentDidMount() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/members?user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
        })
            .then(resp => resp.json())
            .then(resp => {
                let clubs = {}
                resp.clubs.forEach(club => {
                    clubs[club['club']] = club
                })
                this.setState({
                    ...this.state,
                    loading: false,
                    members: resp.members.map(member => {
                        // console.log(member)
                        // console.log(clubs[member['club']])
                        // console.log(clubs[member['club']]['city'])
                        return {
                            ...member,
                            clubStr: member['club'] + ' - ' + clubs[member['club']]['city'],
                        }
                    })
                })
            })
    }

    // downloadMemberData() {
    //     fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/memberData?user=' + this.props.user, {
    //         headers: new Headers({
    //             'Authorization': 'Bearer ' + this.props.accessToken
    //         })
    //     })
    //         .then(resp => resp.text())
    //         .then(csvText => {
    //             let csvContent = "data:text/csv;charset=utf-8," + csvText
    //
    //             var encodedUri = encodeURI(csvContent);
    //             var link = document.createElement("a");
    //             link.setAttribute("href", encodedUri);
    //             link.setAttribute("download", "tangent_india_directory_" + new Date().toLocaleString().replaceAll(':', '_').replaceAll('/', '_').replaceAll(' ', '_').replaceAll(',', '') + ".csv");
    //             document.body.appendChild(link);
    //
    //             link.click();
    //         })
    // }

    downloadMemberData() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/memberData?user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            })
        })
            .then(resp => resp.blob())
            .then(blob => {
                let link = document.createElement("a");
                let objectUrl = URL.createObjectURL(blob);

                link.setAttribute("href", objectUrl);
                link.setAttribute("download", "tangent_india_directory_" + new Date().toLocaleString().replaceAll(':', '_').replaceAll('/', '_').replaceAll(' ', '_').replaceAll(',', '') + ".csv");
                link.style.display = 'none';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Clean up the object URL
                URL.revokeObjectURL(objectUrl);
            });
    }

    render() {

        // console.log(this.state)

        if (this.state.loading) {
            return (
                <Loading/>
            )
        }

        const columns: GridColDef[] = [
            {field: 'memberid', headerName: 'ID', width: 100, sortable: false},
            {field: 'clubStr', headerName: 'Club', width: 200},
            {field: 'name', headerName: 'Name', width: 300},
            {field: 'city', headerName: 'City', width: 150},
            {field: 'phone', headerName: 'Phone', width: 150, sortable: false},
            {
                field: "profile",
                headerName: "Profile",
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    return <Button variant='outline-secondary' as={Link} to={'/members/edit/' + params.id}>Edit
                        Member</Button>;
                }
            },
        ];

        return (
            <Container>
                <h1>Directory</h1>
                <Row>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.memberid}
                        rows={this.state.members}
                        columns={columns}
                        pageSize={this.state.pageSize}
                        rowsPerPageOptions={[25, 50, 100]}
                        onPageSizeChange={(e) => this.setState({...this.state, pageSize: e})}
                        disableSelectionOnClick
                    />
                </Row>
                <Row>
                    <Button variant={'outline-secondary'} onClick={this.downloadMemberData}>Download Member Data</Button>
                </Row>
            </Container>
        );
    }
}