import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../Components/Loading";
import Alert from "react-bootstrap/Alert";

export default class NewEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.changeState = this.changeState.bind(this);
        this.submit = this.submit.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    submit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            ...this.state,
            loading: true
        })
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/events', {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'POST',
            body: JSON.stringify({...this.state, user: this.props.user})
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    success: true,
                    loading: false
                })
            })
    }

    render() {
        return (
            <Container>
                {this.state.loading && <Loading/>}
                {this.state.success && <Alert variant='success'>Event Edited</Alert>}
                <Form onSubmit={this.submit}>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Enter title" value={this.state.title}
                                              name="title" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="location">
                                <Form.Label>Location</Form.Label>
                                <Form.Control type="text" placeholder="Enter location" value={this.state.location}
                                              name="location" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="startTime">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control type="datetime-local" placeholder="Enter start time"
                                              value={this.state.startTime} name="startTime"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="endTime">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="datetime-local" placeholder="Enter end time"
                                              value={this.state.endTime} name="endTime" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter description"
                                              value={this.state.description} name="description"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.loading && <Loading/>}
                    {this.state.success && <Alert variant='success'>Member Edited</Alert>}
                    <Row>
                        <Button type='submit' variant='outline-secondary' onClick={this.submit}>Submit</Button>
                    </Row>
                </Form>
            </Container>
        );
    }
}