import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../Components/Loading";
import Alert from "react-bootstrap/Alert";

export default class ABForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.changeState = this.changeState.bind(this);
        this.submit = this.submit.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    submit(e) {
        e.preventDefault();
        e.stopPropagation();
        // this.setState({
        //     ...this.state,
        //     loading: true
        // })
        // fetch(' https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/member', {
        //     headers: new Headers({
        //         'Authorization': 'Bearer ' + this.props.accessToken
        //     }),
        //     method: 'POST',
        //     body: JSON.stringify({...this.state, user: this.props.user})
        // })
        //     .then(resp => resp.json())
        //     .then(resp => {
        //         this.setState({
        //             ...this.state,
        //             success: true,
        //             loading: false
        //         })
        //     })
    }

    render() {
        return (
            <Container>
                {this.state.loading && <Loading/>}
                {this.state.success && <Alert variant='success'>Form Submitted</Alert>}
                <Form onSubmit={this.submit}>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="club">
                                <Form.Label>Club</Form.Label>
                                <Form.Control type="number" placeholder="Enter club" value={this.state.club} name="club"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="Enter city" value={this.state.city} name="city"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="Enter city" value={this.state.city} name="city"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="pan">
                                <Form.Label>PAN</Form.Label>
                                <Form.Control type="text" placeholder="Enter pan" value={this.state.pan} name="pan"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="dob">
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control type="date" placeholder="Enter dob" value={this.state.dob} name="dob"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="doa">
                                <Form.Label>Date of Anniversary</Form.Label>
                                <Form.Control type="date" placeholder="Enter doa" value={this.state.doa} name="doa"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="number" placeholder="Enter phone" value={this.state.phone}
                                              name="phone" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={this.state.email}
                                              name="email" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="enter address"
                                              value={this.state.address} name="address" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="companyName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter company" value={this.state.companyName}
                                              name="companyName" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="designation">
                                <Form.Label>Designation</Form.Label>
                                <Form.Control type="text" placeholder="Enter designation" value={this.state.designation}
                                              name="designation" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="profession">
                                <Form.Label>Profession</Form.Label>
                                <Form.Control type="text" placeholder="Enter profession" value={this.state.profession}
                                              name="profession" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="specialization">
                                <Form.Label>Specialization</Form.Label>
                                <Form.Control type="text" placeholder="Enter specialization"
                                              value={this.state.specialization} name="specialization"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="officeAddress">
                                <Form.Label>Office Address</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter office address"
                                              value={this.state.officeAddress} name="officeAddress"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="spouseName">
                                <Form.Label>Spouse Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter spouse Name" value={this.state.spouseName}
                                              name="spouseName" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="spouseMobile">
                                <Form.Label>Spouse Mobile</Form.Label>
                                <Form.Control type="number" placeholder="Enter spouse mobile"
                                              value={this.state.spouseMobile} name="spouseMobile"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                        <Col md>
                            <Form.Group className="mb-3" controlId="spouseDob">
                                <Form.Label>Spouse Dob</Form.Label>
                                <Form.Control type="date" placeholder="Enter spouse dob" value={this.state.spouseDob}
                                              name="spouseDob" onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.loading && <Loading/>}
                    {this.state.success && <Alert variant='success'>Member Created</Alert>}
                    <Row>
                        <Button type='submit' variant='outline-secondary' onClick={this.submit} disabled={this.state.success}>Submit</Button>
                    </Row>
                </Form>
            </Container>
        );
    }
}