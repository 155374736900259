import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import {withAuth0} from "@auth0/auth0-react";

class Navigation extends React.Component {
    render() {
        const {logout} = this.props.auth0;
        return (
            <div>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Brand as={Link} to="/">Tangent India Admin</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                                <Button variant='outline-secondary'
                                        onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>
                                    Log Out
                                </Button>

                                {/*<Nav.Link href="#link">Link</Nav.Link>*/}
                                {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">*/}
                                {/*    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item href="#action/3.2">*/}
                                {/*        Another action*/}
                                {/*    </NavDropdown.Item>*/}
                                {/*    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                                {/*    <NavDropdown.Divider />*/}
                                {/*    <NavDropdown.Item href="#action/3.4">*/}
                                {/*        Separated link*/}
                                {/*    </NavDropdown.Item>*/}
                                {/*</NavDropdown>*/}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default withAuth0(Navigation);