import React from "react";
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {Container} from "@mui/material";
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Loading from "../Components/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageSize: 25,
            pastPageSize: 25,
            deletedPageSize: 25,
            loading: true,
            events: []
        }
    }

    componentDidMount() {
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/events?user=' + this.props.user, {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
        })
            .then(resp => resp.json())
            .then(resp => {
                let allEvents = resp;
                let deletedEvents = allEvents.filter(event => event.deleted).sort((event1, event2) => event1.startTime.localeCompare(event2.startTime))
                let pastEvents = allEvents.filter(event => !event.deleted && new Date(event.endTime) < new Date()).sort((event1, event2) => event1.startTime.localeCompare(event2.startTime))
                let currentEvents = allEvents.filter(event => !event.deleted && new Date(event.endTime) >= new Date()).sort((event1, event2) => event1.startTime.localeCompare(event2.startTime))
                this.setState({
                    ...this.state,
                    loading: false,
                    // events: resp.filter(event => new Date(event.endTime) >= new Date()).sort((event1, event2) => event1.startTime.localeCompare(event2.startTime)),
                    // pastEvents: resp.filter(event => new Date(event.endTime) < new Date()).sort((event1, event2) => event1.startTime.localeCompare(event2.startTime)),
                    events: currentEvents,
                    deletedEvents: deletedEvents,
                    pastEvents: pastEvents
                })
            })
    }

    render() {

        if (this.state.loading) {
            return (
                <Loading/>
            )
        }

        const columns: GridColDef[] = [
            // {
            //     field: "eventid",
            //     headerName: "Event ID",
            //     sortable: false,
            //     width: 150,
            //     renderCell: (params) => {
            //         return <span>{params.id}</span>;
            //     }
            // },
            // {field: 'id', headerName: 'ID', width: 100},
            {
                field: 'start',
                headerName: 'Start Time',
                width: 150,
                sortable: false,
                renderCell: (params) => {
                    return <>{getDateTime(params.row.startTime)}</>
                }
            },
            {field: 'title', headerName: 'Title', width: 300},
            {
                field: 'end',
                headerName: 'End Time',
                width: 150,
                sortable: false,
                renderCell: (params) => {
                    return <>{getDateTime(params.row.endTime)}</>
                }
            },
            {field: 'location', headerName: 'Location', width: 200},
            {
                field: "view",
                headerName: "View Event",
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    return <Button variant='outline-secondary' as={Link} to={'/events/' + params.id}>Edit
                        Event</Button>;
                }
            },
        ];

        return (
            <Container>
                <Row>
                    <Col md>
                        <h1>Events</h1>
                    </Col>
                    <Col md>
                        <Button variant='outline-secondary' as={Link} to={'/events/add'}
                                style={{float: 'right', marginTop: '1%'}}>New Event</Button>
                    </Col>
                </Row>
                <Row>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.id}
                        rows={this.state.events}
                        columns={columns}
                        pageSize={this.state.currentPageSize}
                        rowsPerPageOptions={[25, 50, 100]}
                        onPageSizeChange={(e) => this.setState({...this.state, currentPageSize: e})}
                        disableSelectionOnClick
                    />
                </Row>
                <Row>
                    <h2>Past Events</h2>
                </Row>
                <Row>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.id}
                        rows={this.state.pastEvents}
                        columns={columns}
                        pageSize={this.state.pastPageSize}
                        rowsPerPageOptions={[25, 50, 100]}
                        onPageSizeChange={(e) => this.setState({...this.state, pastPageSize: e})}
                        disableSelectionOnClick
                    />
                </Row>
                <Row>
                    <h2>Deleted Events</h2>
                </Row>
                <Row>
                    <DataGrid
                        autoHeight
                        getRowId={(row) => row.id}
                        rows={this.state.deletedEvents}
                        columns={columns}
                        pageSize={this.state.deletedPageSize}
                        rowsPerPageOptions={[25, 50, 100]}
                        onPageSizeChange={(e) => this.setState({...this.state, deletedPageSize: e})}
                        disableSelectionOnClick
                    />
                </Row>
            </Container>
        );
    }
}

function getDateTime(original) {
    if (!original || original.length === 0) {
        return '';
    }
    return original.split('T')[0].replaceAll('-', '/') + ' ' + original.split('T')[1]
}