import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../Components/Loading";
import Alert from "react-bootstrap/Alert";

export default class AddClub extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.changeState = this.changeState.bind(this);
        this.submit = this.submit.bind(this);
    }

    changeState(e) {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    submit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            ...this.state,
            loading: true
        })
        fetch('https://c8xisbe100.execute-api.ap-south-1.amazonaws.com/club', {
            headers: new Headers({
                'Authorization': 'Bearer ' + this.props.accessToken
            }),
            method: 'POST',
            body: JSON.stringify({...this.state, user: this.props.user})
        })
            .then(resp => resp.json())
            .then(resp => {
                this.setState({
                    ...this.state,
                    success: true,
                    loading: false
                })
            })
    }

    render() {
        return (
            <Container>
                {this.state.loading && <Loading/>}
                {this.state.success && <Alert variant='success'>Club Added</Alert>}
                <Form onSubmit={this.submit}>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="clubNumber">
                                <Form.Label>Club Number</Form.Label>
                                <Form.Control type="number" placeholder="Enter club number" value={this.state.clubNumber} name="clubNumber"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Club Name</Form.Label>
                                <Form.Control type="input" placeholder="Enter club name" value={this.state.clubName} name="clubName"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="sponsorClub">
                                <Form.Label>Sponsor Club</Form.Label>
                                <Form.Control type="number" placeholder="Enter sponsor club" value={this.state.sponsorClub} name="sponsorClub"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md>
                            <Form.Group className="mb-3" controlId="charterDate">
                                <Form.Label>Charter Date</Form.Label>
                                <Form.Control type="input" placeholder="Enter charter date" value={this.state.charterDate} name="charterDate"
                                              onChange={this.changeState}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {this.state.loading && <Loading/>}
                    {this.state.success && <Alert variant='success'>Club Added</Alert>}
                    <Row>
                        <Button type='submit' variant='outline-secondary' onClick={this.submit}>Submit</Button>
                    </Row>
                </Form>
            </Container>
        );
    }

}